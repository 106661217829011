<template>
  <div class="tab-content" id="PTtab-8" :key="'PTtab-8'" >
    <div>
      <div class="pagenamPT">
        <h3 class="pagename2 w100w">소속 전체 회원 목록</h3>
      </div>
      <div class="sch_PC">
        <div class="PTsch flex-c">
          <div class="datesearchPTWarp flex-c gap-2">
            <h4>날짜</h4>
            <date-filter :retail="true" :defaultDay="0"
                         @search="getMyPartnerList(1)"
                         @update="onChangeDateTable"
                         :startDate="reqData.startDate"
                         :endDate="reqData.endDate" :id="'main-date-checkbox1'" @setOldYn="setOldYn" :oldYn="reqData.oldYn" :isOldYn="true"/>
          </div>
        </div>
      </div>
      <div class="sch_M">
        <div class="PTsch flex-c">
          <div class="datesearchM">
            <date-filter-mobile :id="'main-date-checkbox1'" :isOldYn="true" :retail="true"/>
         </div>
        </div>
      </div>
      <div class="sch_Normal">
        <div class="sch_Btm">
          <div class="searchPTwrap">
            <div class="searchPT w30p">
              <div class="datesearchPT flex-c gap-2">
                <h4>구분</h4>
                <select class="h36px">
                  <option value="memId" selected>아이디</option>
                  <option value="memNick">닉네임</option>
                  <option value="recommenderId">상위유저</option>
                </select>
              </div>
            </div>
            <div class="searchPT">
              <input class="w100-100w h36px" type="text" :placeholder="$t('front.search.emptySearch')" v-model="searchMemId"/>
            <a @click="getPartnerMemberList(1)" class="sch_Icon">
              <img src="@/assets/img/search.png" alt=""/>
            </a>
            </div>
          </div>
          <div class="searchPTwrap">
            <div class="datesearchPT flex-c gap-2 w50p">
              <h4>정렬</h4>
              <select v-model="orderStr" class="h36px">
                <option value=""></option>
                <option value="cashAmt_DESC">보유머니 많은순</option>
                <option value="cashAmt_ASC">보유머니 적은순</option>
                <option value="pointAmt_DESC">보유포인트 많은순</option>
                <option value="pointAmt_ASC">보유포인트 적은순</option>
                <option value="totalCashInAmt_DESC">입금금액 많은순</option>
                <option value="totalCashInAmt_ASC">입금금액 적은순</option>
                <option value="totalCashOutAmt_DESC">출금금액 많은순</option>
                <option value="totalCashOutAmt_ASC">출금금액 적은순</option>
                <option value="totalCashProfit_DESC">입출손익 많은순</option>
                <option value="totalCashProfit_ASC">입출손익 적은순</option>
                <option value="totalBetAmt_DESC">베팅금 많은순</option>
                <option value="totalBetAmt_ASC">베팅금 적은순</option>
                <option value="totalBetWinAmt_DESC">당첨금 많은순</option>
                <option value="totalBetWinAmt_ASC">당첨금 적은순</option>
                <option value="totalWlAmt_DESC">베팅손익 많은순</option>
                <option value="totalWlAmt_ASC">베팅손익 적은순</option>
                <option value="joinDate_DESC">가입일시 오래된순</option>
                <option value="joinDate_ASC">가입일시 최신순</option>
              </select>
            </div>
          </div>
        </div>
      </div>
      <div class="strTableWrap">
        <div class="subMemWrap">
          <ul class="subPT_1">
            <h3>하부 파트너 목록</h3>
            <!-- <li>
              <a>
                <span class="box" :class="userData.partnerLevel">{{partnerLevelName(userData.partnerLevel)}}</span>
                {{userData.memId}}
              </a>
            </li> -->
            <li v-for="(item1) in partnerList" :key="item1.memId" :class="{'morebar':item1.open}">
              <span class="arr" v-if="item1.cnt" :class="[item1.partnerLevel, {'moreOn':item1.open}]" @click="listOpen(item1.memId, 'sub', item1)"></span>
              <span class="arr normal" v-if="!item1.cnt"></span>
              <a :class="[item1.partnerLevel, { 'active': selectedLi === item1.memId }]" @click="getMyPartnerList(item1.memId, 'sub', item1)">
                <span class="box" :class="item1.partnerLevel">본사</span>
                {{item1.memId}}
              </a>

              <ul class="subPT_2" v-show="item1.open && item1.children">
                <li v-for="item2 in item1.children" :key="item2.memId" :class="{'morebar':item2.open}">
                  <span class="arr" v-if="item2.cnt" :class="[item2.partnerLevel,{'moreOn':item2.open}]" @click="listOpen(item2.memId, 'sub', item2)"></span>
                  <span class="arr normal" v-if="!item2.cnt"></span>
                  <a :class="[item2.partnerLevel, { 'active': selectedLi === item2.memId }]" @click="getMyPartnerList(item2.memId, 'sub', item2)">
                    <span class="box" :class="item2.partnerLevel">부본</span>
                    {{item2.memId}}
                  </a>

                  <ul class="subPT_3" v-show="item2.open && item2.children">
                    <li v-for="item3 in item2.children" :key="item3.memId" :class="{'morebar':item3.open}">
                      <span class="arr" v-if="item3.cnt" :class="[item3.partnerLevel,{'moreOn':item3.open}]" @click="listOpen(item3.memId, 'sub', item3)"></span>
                      <span class="arr normal" v-if="!item3.cnt"></span>
                      <a :class="[item3.partnerLevel, { 'active': selectedLi === item3.memId }]" @click="getMyPartnerList(item3.memId, 'sub', item3)">
                        <span class="box" :class="item3.partnerLevel">{{partnerLevelName(item3.partnerLevel)}}</span>
                        {{item3.memId}}
                      </a>

                      <ul class="subPT_4" v-show="item3.open && item3.children">
                        <li v-for="item4 in item3.children" :key="item4.memId" :class="{'morebar':item4.open}">
                          <span class="arr" v-if="item4.cnt" :class="[item4.partnerLevel,{'moreOn':item4.open}]" @click="listOpen(item4.memId, 'sub', item4)"></span>
                          <span class="arr normal" v-if="!item4.cnt"></span>
                          <a :class="[item4.partnerLevel, { 'active': selectedLi === item4.memId }]" @click="getMyPartnerList(item4.memId, 'sub', item4)">
                            <span class="box" :class="item4.partnerLevel">{{partnerLevelName(item4.partnerLevel)}}</span>
                            {{item4.memId}}
                          </a>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
          </ul>
        </div>
        <div class="strTablescr">
          <div class="strTablePC">
            <table class="strTablePT">
              <thead>
              <tr>
                <th>아이디</th>
                <th>닉네임</th>
                <th>롤링/루징<br>(카지노)</th>
                <th>보유머니</th>
                <th>보유포인트</th>
                <th>입/출금액</th>
                <th>입출손익</th>
                <th>가입 일시</th>
              </tr>
              </thead>
              <tbody>
                <template v-if="memberList.length > 0">
                  <tr v-for="(item) in memberList" :key="item.memId">
                    <td :class="item.myLevel">{{item.memId}}</td>
                    <td class="nick">{{item.memNick}}</td>
                    <td>
                      <div class="btn-input-wrap">
                        <span class="rate-font">{{item.pointRate}}%/{{item.loseRate}}%</span>
                        <a class="plus-btn" @click="onOpenRate(item)"></a>
                      </div>
                    </td>
                    <td>{{thousand(item.cashAmt)}}</td>
                    <td>{{thousand(item.pointAmt)}}</td>
                    <td>
                      <div class="twoline-wrap">
                        <span><em>{{ $t('front.common.deposit') }}</em>{{thousand(item.totalCashInAmt)}}</span>
                        <span><em>{{ $t('front.common.withdrawal') }}</em>{{thousand(item.totalCashOutAmt)}}</span>
                      </div>
                    </td>
                    <td>
                      <div :class="{'rdc' : item.totalCashProfit > 0, 'blc' : item.totalCashProfit < 0}">{{thousand(item.totalCashProfit)}}</div>
                    </td>
                    <td>{{dateFormatAll(item.joinDate)}}</td>
                  </tr>
                </template>
                <template v-else>
                  <tr>
                    <td colspan="8">소속 회원이 없습니다.</td>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>
          <div class="strTableM">
              <div class="strTablePTM">
                <ul v-for="(item) in memberList" :key="item.memId">
                  <li :class="item.myLevel">
                    <em>아이디</em>
                    <div>{{item.memId}}</div>
                  </li>
                  <li>
                    <em>닉네임</em>
                    <div class="nick">{{item.memNick}}</div>
                  </li>
                  <li>
                    <em>보유머니</em>
                    <div>{{thousand(item.cashAmt)}}</div>
                  </li>
                  <li>
                    <em>보유머니</em>
                    <div>{{thousand(item.pointAmt)}}</div>
                  </li>
                  <li>
                    <em>입/출금액</em>
                    <div><!--column-->
                      <ul class="m-table-c">
                        <li>
                          <span>{{ $t('front.common.deposit') }}</span>
                          <span>{{thousand(item.totalCashInAmt)}}</span>
                        </li>
                        <li>
                          <span>{{ $t('front.common.withdrawal') }}</span>
                          <span>{{thousand(item.totalCashOutAmt)}}</span>
                        </li>
                      </ul>
                    </div>
                  </li>
                  <li>
                    <em>입/출금액</em>
                    <div>{{thousand(item.totalCashProfit)}}</div>
                  </li>
                  <li>
                    <em>롤링/루징<br>(카지노)</em>
                    <div class="btn-input-wrap">
                      <span class="rate-font">{{item.pointRate}}%/{{item.loseRate}}%</span>
                      <a class="plus-btn" @click="onOpenRate(item)"></a>
                    </div>
                  </li>
                  <li>
                    <em>가입 일시</em>
                    <div>{{dateFormatAll(item.joinDate)}}</div>
                  </li>
                  <!-- <li><em>머니 관리</em>
                      <a class="bgr btn2 btn3" @click="onOpenMoneyPopup(item)">머니 지급</a>
                      <a class="bgg btn3" @click="setMoneyMerge(item)">통합머니전환</a>
                      <div v-if="item.openPopup" class="moneyg">
                        <a class="close" @click="onCloseMoneyPopup(item)"><img src="@/assets/img/icon_cancelB.svg" /></a>
                        <h2>보유머니: <span>{{thousand(item.cashAmt)}}</span></h2>
                        <input type="text" pattern="[0-9.,]+" :placeholder="$t('front.give.moneyInput')" v-model="item.inputCashAmt" @keyup="updateCashAmt(item, $event.target.value)" />
                        <div class="mbtn">
                            <a @click="setMoney(item, 10000)">1{{$t('front.cash.manwon')}}</a>
                            <a @click="setMoney(item, 30000)">3{{$t('front.cash.manwon')}}</a>
                            <a @click="setMoney(item, 50000)">5{{$t('front.cash.manwon')}}</a>
                            <a @click="setMoney(item, 100000)">10{{$t('front.cash.manwon')}}</a>
                        </div>
                        <div class="mbtn">
                            <a @click="setMoney(item, 30000)">3{{$t('front.cash.manwon')}}</a>
                            <a @click="setMoney(item, 50000)">5{{$t('front.cash.manwon')}}</a>
                            <a @click="setMoney(item, 100000)">10{{$t('front.cash.manwon')}}</a>
                            <a @click="setMoney(item, 0)" style="background: #730000;">{{$t('front.cash.correct')}}</a>
                        </div>
                        <div class="submit">
                            <a class="bgb btn2" @click="memCash('-3', item)">{{$t('front.give.give')}}</a>
                            <a class="bgr btn2" @click="memCash('3', item)">{{$t('front.give.back')}}</a>
                        </div>
                      </div>
                  </li> -->
                </ul>
              </div>
          </div>
        </div>
      </div>

      <pagination v-if="pageInfo"
                  :pageNum="pageInfo.page"
                  :pageSize="pageInfo.count_per_list"
                  :totalCount="pageInfo.tatal_list_count"
                  :className="'partnerPaging'"
                  @goToPage="getPartnerMemberList"
      />
    </div>

    <transition name="fade">
      <TMmodal v-show="TMOpen" :isOpen="TMOpen" :type="'partner'" @close="onCloseTM" :userData="selectMem"/>
    </transition>
    <transition name="fade">
      <RateTable v-if="RateOpen" :selectMem="selectMem" @close="onCloseRate"/>
    </transition>
  </div>
</template>

<script>
import Pagination from '@/components/ui/Pagination.vue'
import {
  getRetailMyMemDirectList,
  partnerLevels, partnerMyMemIds, retailMyPartnerDirectList
} from '@/api/retail'
import { getDateStr, thousand } from '@/libs/utils'
import { memCashInOut } from '@/api/give'
import { mapState } from 'vuex'
import { PARTNER_LEVEL_NAME } from '@/libs/constants'
import TMmodal from '@/components/common/TotalMoney.vue'
import RateTable from '@/components/common/RateTable.vue'
import DateFilter from '@/components/ui/DateFilter'
import DateFilterMobile from '@/components/ui/DateFilterMobile'
import { addDays } from 'date-fns'

export default {
  name: 'subMemberList',
  components: { TMmodal, Pagination, RateTable, DateFilter, DateFilterMobile },
  computed: {
    ...mapState([
      'userData',
      'gameCount',
      'commonCodeByOrder',
      'commonCodeByCode'
    ]),

    formattedCashAmt () {
      if (!this.cashAmt) return ''
      return this.cashAmt.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    }
  },
  data () {
    return {
      searchType: 'N',
      searchPartnerLevel: '',
      partnerLevelList: [],
      memberList: [],
      partnerList: [],
      partnerLevelObject: null,
      summaryNew: null,
      searchMemId: '',
      cashAmt: '',
      selectMem: {
        cashAmt: ''
      },
      list: [],
      isProcessing: false,
      moveTarget: null,
      TMOpen: false,
      subMenus: {
        1: true,
        2: false,
        3: false,
        4: false
      },
      selectedLi: null,
      idlist: false,
      RateOpen: false,
      orderStr: '',
      reqData: {
        startDate: '',
        endDate: getDateStr(addDays(new Date(), 0), 'yyyy-MM-dd 23:59:59'),
        oldYn: false
      }
    }
  },
  async created () {
    this.emitter.emit('Loading', true)
    this.getPartnerLevels()
    this.searchMemId = this.userData.memId
    // this.getPartnerMemberList(1)
    this.getMyPartnerList(this.userData.memId)
    this.emitter.emit('Loading', false)
  },
  watch: {
    orderStr () {
      this.getPartnerMemberList(1)
    },
    selectPartnerCode () {
      if (this.selectPartnerCode) {
        this.selectMem.cashAmt = ''
        this.getMyMemIds()
      }
    },
    selectMem () {
      if (this.selectMem) {
        this.selectMem.cashAmt = thousand(this.selectMem.cashAmt)
      }
    }
  },
  methods: {
    setOldYn (data) {
      this.reqData.oldYn = data
    },
    onChangeDateTable (value) {
      this.reqData.startDate = getDateStr(new Date(value.startDate))
      this.reqData.endDate = getDateStr(new Date(value.endDate))
    },
    onOpenRate (selectMem) {
      this.selectMem = {
        ...selectMem
      }
      this.RateOpen = true
    },
    onCloseRate () {
      this.RateOpen = false
      this.selectMem = null
    },
    onClosePay () {
      this.RateOpen = false
    },
    thousand,
    partnerLevelName (partnerLevel) {
      return PARTNER_LEVEL_NAME[partnerLevel]
    },
    toggleSubMenu (subMenuId) {
      this.subMenus[subMenuId] = !this.subMenus[subMenuId]
    },
    parseFormattedValue (value) {
      return value.replace(/,/g, '')
    },
    onCloseTM () {
      this.selectMem = null
      this.TMOpen = false
    },
    onChangePartnerLevel (partnerLevel) {
      this.searchPartnerLevel = partnerLevel
      this.getPartnerMemberList(1)
    },
    getMyMemIds () {
      const params = {
        code: this.selectPartnerCode,
        masterCode: 'partner'
      }
      partnerMyMemIds(params).then(res => {
        const result = res.data
        if (result.resultCode === '0') {
          this.myMemIdsList = result.data.memIds
        }
      })
    },
    listOpen (memId, type, item) {
      this.emitter.emit('Loading', true)
      const params = {
        memId: memId
      }
      this.searchMemId = memId
      retailMyPartnerDirectList(params).then(res => {
        const result = res.data
        if (result.resultCode === '0') {
          const list = result.data.list
          if (type === 'sub') {
            item.children = list
            item.open = !item.open
          } else {
            this.partnerList = list
          }
        }
        this.emitter.emit('Loading', false)
      })
    },
    getMyPartnerList (memId, type, item) {
      this.emitter.emit('Loading', true)
      const params = {
        memId: memId
      }
      this.searchMemId = memId

      console.log('[REQ]retailMyPartnerDirectList : ', params)
      retailMyPartnerDirectList(params).then(res => {
        console.log('[RES]retailMyPartnerDirectList : ', res.data)
        const result = res.data
        if (result.resultCode === '0') {
          const list = result.data.list
          if (type === 'sub') {
            item.children = list

            if (this.selectedLi === memId) {
              this.selectedLi = memId
            } else {
              this.selectedLi = memId
            }

            this.getPartnerMemberList(1)
          } else {
            this.partnerList = list
          }
        }
        this.emitter.emit('Loading', false)
      })
    },
    getPartnerMemberList (page) {
      this.emitter.emit('Loading', true)
      if (!page) {
        page = this.pageInfo.page
      }
      const params = {
        memId: this.searchMemId,
        page: page,
        partnerLevel: this.searchPartnerLevel,
        searchType: this.searchType,
        count_per_list: 40
      }

      if (this.orderStr) {
        const orderColumn = this.orderStr.split('_')[0]
        const orderType = this.orderStr.split('_')[1]

        params.orderColumn = orderColumn
        params.orderType = orderType
      }
      console.log('[REQ]getRetailMyMemList : ', params)
      getRetailMyMemDirectList(params).then(res => {
        console.log('[RES]getRetailMyMemList : ', res.data)
        this.emitter.emit('Loading', false)
        const result = res.data
        if (result.resultCode === '0') {
          console.log(result.list)
          this.memberList = result.data.list

          this.memberList.forEach(item => {
            item.inputCashAmt = ''
          })

          if (result.data.pageInfo) {
            this.pageInfo = result.data.pageInfo
          }
        }
      })
    },
    getPartnerLevels () {
      partnerLevels({}).then(res => {
        const result = res.data
        if (result.resultCode === '0') {
          console.log('partnerLevel : ', result.data)
          this.partnerLevelList = result.data.list
          this.newPartnerLevel = result.data.list[0]

          const partnerObj = {}
          for (let i = 0; i < this.partnerLevelList.length; i++) {
            const item = this.partnerLevelList[i]
            const code = item.code
            const codeName = item.codeName

            if (!partnerObj[code]) {
              partnerObj[code] = codeName
            }
          }

          this.partnerLevelObject = partnerObj
        }
      })
    },
    async memCash (type, item) {
      const memId = item.memId
      const cashStr = item.inputCashAmt
      console.log(item)
      if (!cashStr) {
        await this.onAlert('warningart', 'front.give.moneyInput')
        return false
      }

      if (!memId) {
        await this.onAlert('warningart', '회원 아이디를 선택해주세요.')
        return false
      }

      if (!this.isProcessing) {
        const cash = Number(cashStr.replace(/,/g, ''))
        this.isProcessing = true

        const params = {
          memId: memId
        }
        if (type === '-3') {
          params.inAmt = cash
        } else {
          params.outAmt = cash
        }

        const message = this.$t('front.cash.moneyMoveConfirm', { memNick: memId, cashAmt: cashStr, type: type === '-3' ? '지급' : '회수' })

        const confirm = await this.onConfirm(message)
        if (confirm) {
          this.emitter.emit('Loading', true)
          memCashInOut(params, type).then(async response => {
            const result = response.data
            console.log(result)
            this.emitter.emit('Loading', false)
            if (result.resultCode === '0') {
              if (type === '-3') {
                await this.onCheck('지급 완료')
              } else {
                await this.onCheck('회수 완료')
              }
              item.inputCashAmt = ''
              this.getPartnerMemberList(this.pageInfo.page)
            } else {
              await this.onAlert('warningart', result.resultMessage)
            }
            this.isProcessing = false
          })
        } else {
          this.isProcessing = false
        }
      }
    },
    updateCashAmt (item, value) {
      const parts = item.inputCashAmt.split('.')
      const v = parts[0].replace(/\D/g, '')
      const dec = parts[1]
      // const calcNum = Number((dec !== undefined ? v + '.' + dec : v))
      let n = new Intl.NumberFormat('en-EN').format(v)
      n = dec !== undefined ? n + '.' + dec : n
      if (v === '0' || v === '') {
        item.inputCashAmt = ''
      } else {
        item.inputCashAmt = n
      }
    },
    setMoney (item, value) {
      const currentMoney = Number(item.inputCashAmt.replace(/,/g, ''))
      console.log(currentMoney)
      if (value === 0) {
        item.inputCashAmt = (value).toString()
      } else {
        item.inputCashAmt = (currentMoney + value).toString()
      }
      this.updateCashAmt(item)
    }
  }
}
</script>
<style scoped src="@/styles/striNew.css"></style>
<style scoped src="@/styles/common.css"></style>
<style scoped>
.strTablescr {width: 100%;}
@media (max-width: 1000px) {
  .strTablescr {width: 100%; background: none;}
  .strTablePTM ul {padding: 0;}
}
</style>
